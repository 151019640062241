import { PostRequest, successParam } from '@/api/base';
import GetRequest from '@/data/basic';

export function deleteDevice(params: object, callback: successParam) {
    PostRequest('v3/web/common/device/delete', params, callback);
}

export function resetDevice(params: object, callback: successParam) {
    PostRequest('v3/web/common/device/reset', params, callback);
}

export function remoteStatusByDevice(params: object, callback: Function) {
    GetRequest('v3/web/common/device/remoteStatus', params, callback);
}

export default null;
